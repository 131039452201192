import React, {Component} from 'react'

import {Line} from 'react-chartjs-2';

window.data = {
  drinks: []
  // drinks: [{
  //   ml: 50,
  //   percent: 40,
  //   time: 0
  // }
};

var firstDrinkTime = 1575953254;
window.firstDrinkTime = firstDrinkTime || 0;

const dateToTime = (dateObj) => {
  const utcString = dateObj.toUTCString(); 
  return utcString.slice(-12, -7); 
};

// floor to nearest 5
const firstMinutes = parseInt(dateToTime(new Date(firstDrinkTime * 1000)).substring(3));
const roundedDownMin = parseInt(firstMinutes / 5) * 5;
firstDrinkTime -= (firstMinutes - roundedDownMin)*60;

const MAX_X_MINUTES = 480;

const PEAKING_PERIOD = 15; // takes 15 min for one drink to peak
const STEP_SIZE = 1; // 

var toAMPM = function (fourDigitTime){
  var hours24 = parseInt(fourDigitTime.substring(0,2));
  var hours = ((hours24 + 11) % 12) + 1;
  var amPm = hours24 > 11 ? 'PM' : 'AM';
  var minutes = fourDigitTime.substring(3);

  return hours + ':' + minutes + ' ' + amPm;
}

const calculateBACs = (weight, isMale) => {
  const data = window.data;

  // give every drink an amount of BAC
  for (const d of data.drinks)
  {
    let resistance = isMale ? .073 : .066
    let alcohol = d.percent * d.ml/29.574
    d.maxBAC = (alcohol * resistance/weight);
    d.bac = d.maxBAC;  // current bac level, after the drink starts taking effect
  }

  console.log(data.drinks);

  let out = [];
  for (let x=0; x < MAX_X_MINUTES; x += STEP_SIZE) // hardcoded to 2 hours, every 5 min
  {
    let curBAC = 0;
    let activeDrinks = 0;

    for (const d of data.drinks) {
      activeDrinks += (x >= d.time && d.bac > 0);
    }

    for (const d of data.drinks)
    {
      if (d.bac <= 0) {
        // this drink's current bac has dropped to 0, skip it
        continue;
      }
  
      if (x < d.time) {
        // the time for this drink hasn't happened yet (for this x), skip for now
        continue;
      }

      const curPeakTime = d.time + PEAKING_PERIOD;
      
      if (x >= d.time && x < curPeakTime) {
        // we're within the peaking period, this isn't an active drink, and it should
        // only receive a portion of it's potential max BAC
        curBAC += (((x - d.time) / PEAKING_PERIOD)) * d.maxBAC;
        continue;
      }

      // apply sobering rate to this drink
      d.bac -= 0.015/(60/STEP_SIZE)/activeDrinks;

      if (d.bac <= 0) {
        // this drink's current bac has dropped to 0, skip it
        continue;
      }

      // update current BAC
      curBAC += d.bac
    }

    out.push(curBAC);
  }

  console.log(out);

  return out;
};

class Chart extends Component {
    constructor(props){
        super(props)

        // load the saved drink data!
        window.data.drinks = JSON.parse(window.localStorage.getItem("drinks") || "[]");

        const calculatedData = calculateBACs(155, true);

        this.state = {
            chartData : {
                    labels: Array.from({length: MAX_X_MINUTES / STEP_SIZE}, (x, i) => i*STEP_SIZE),
                datasets: [
                    {
                        label: "BAC",
                        data: calculatedData,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        pointRadius: 0
                    },
                    {
                      label: "Drinks",
                      data: window.data.drinks.map(d => ({
                        x: d.time,
                        y: calculatedData[d.time / STEP_SIZE]
                      })),
                      backgroundColor: 'rgba(2, 99, 132, 0.6)',
                      pointRadius: 5,
                      borderWidth: 0,
                      borderColor: 'rgba(0, 0, 0, 0)',
                      fill: false
                    }
                ],
            }
        }
    }
    render() {
        return (
            <div className="chart">
                Chart Component
                <Line
                    data={this.state.chartData}
                    options={{
                      maintainAspectRatio: true,
                      scales: {
                        yAxes: [{
                          ticks: {
                              beginAtZero: true,
                              min: 0,
                              max: .3
                          }
                      }],
                        xAxes: [{
                          ticks: {
                              min: 0,
                              max: MAX_X_MINUTES,
                              maxTicksLimit: MAX_X_MINUTES / (60/4),
                              callback: (value, i, values) => {
                                const offset = -((new Date()).getTimezoneOffset()/60);  
                                const dateObj = new Date((i*STEP_SIZE*60 + firstDrinkTime + offset * 3600) * 1000); 
                                const time = dateToTime(dateObj);
            
                                return toAMPM(time);
                            }
                          }
                      }]
                      }
                    }}
                />
            </div>
        )
    }
}

export default Chart
