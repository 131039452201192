import React, {Component} from 'react'

const update = () => {
  // svae it for later!
  window.localStorage.setItem("drinks", JSON.stringify(window.data.drinks));

  // refresh TODO: update without refreshing
  window.location.reload();
};

class DrinkInputter extends Component {
    render() {
        const drinks = window.data.drinks;

        const info = drinks.map((d, i) => <div className="drinkInput">
          <div>drink {i+1}: </div>
          <div><input readonly placeholder="date" value={d.time}></input></div>
          <div><input readonly placeholder="mL" value={d.ml}></input></div>
          <div><input readonly placeholder="%" value={d.percent}></input></div>
          <button onClick={() => {
            window.data.drinks.splice(i, 1);
            update();
          }}>delete</button>
        </div>);

        return (
            <div>
                {info}
                <div className="drinkInput">
                  <div>new drink:</div>
                  <div><input id="newTime" placeholder="time" defaultValue={Math.floor(((Date.now() / 1000) - window.firstDrinkTime)/60)}></input></div>
                  <div><input id="newML" placeholder="mL" defaultValue="50"></input></div>
                  <div><input id="newPercent" placeholder="%" defaultValue="40"></input></div>
                  <button onClick={() => {
                    window.data.drinks.push({
                      ml: Number(document.getElementById("newML").value),
                      percent: Number(document.getElementById("newPercent").value),
                      time: Number(document.getElementById("newTime").value)
                    });
                    update();
                  }}>add</button>
                </div>
            </div>
        )
    }
}

export default DrinkInputter;
