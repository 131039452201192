import React from 'react';
import './App.css';
import Chart from './components/Chart'
import DrinkInputter from './components/DrinkInputter'

function App() {
  return (
    <div className="App">
      <Chart />
      <DrinkInputter />
    </div>
  );
}

export default App;
